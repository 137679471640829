import Lottie from "lottie-react";
import EmailConfirmation from "../assets/animations/email_confirmed.json";
import { Col, Row } from "react-bootstrap";

export default function EmailConfirmationSuccessfulScreen() {
    return (
        <div className="px-5 py-3 d-flex justify-content-center align-items-center h-100">
            <div className="mt-3">
                <Row className="d-flex justify-content-center">
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <Lottie
                            animationData={EmailConfirmation}
                            loop={false}
                        />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={8} xl={8} md={10} sm={10}>
                        <h1 className="qp-text-green text-center">
                            Great news! Your email is activated. Thank you for
                            completing the process.
                        </h1>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
