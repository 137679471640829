import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import HorizontalLogo from "../assets/images/horizontal_logo.png";
import { NavLink } from "react-router-dom";

function BasicExample() {
    return (
        <Navbar
            expand="lg"
            className="bg-white px-5 py-3 navbar-shadow"
            sticky="top"
        >
            <Navbar.Brand>
                <NavLink to="/">
                    <img
                        alt="QuickPurse Logo"
                        src={HorizontalLogo}
                        height="30"
                        className="d-inline-block align-top"
                    />
                </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <NavLink to="/login" className="nav-link">
                        Login
                    </NavLink>
                    <NavLink to="/create_account" className="nav-link">
                        Create Account
                    </NavLink>
                    <NavLink to="/faqs" className="nav-link">
                        FAQs
                    </NavLink>
                    <NavLink to="/contact_us" className="nav-link">
                        Contact Us
                    </NavLink>
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                            Separated link
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default BasicExample;
