import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader";

export default function CreateAccountScreen() {
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState({
        fullName: "",
        dob: "",
        bvn: "",
        nin: "",
        email: "",
        phone: "",
        password: "",
        web: true,
    });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            await axios.post("register/", formData);
        } catch (err) {
            enqueueSnackbar(
                "We couldn't validate your full name with the BVN you provided",
                { variant: "error" }
            );
        } finally {
            setLoading(false);
        }
    };

    return loading ? (
        <Loader />
    ) : (
        <div className="d-flex justify-content-center vh-100 mt-5 mb-5">
            <div className="w-100 mx-3" style={{ maxWidth: "400px" }}>
                <h1
                    className="qp-text-green text-center"
                    style={{ fontSize: "2.5em" }}
                >
                    <strong>Create Account</strong>
                </h1>
                <h6 className="text-center mb-4">
                    Fill in your details to create your account.
                </h6>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="fullName" className="mb-3">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your full name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="dob" className="mb-3">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="bvn" className="mb-3">
                        <Form.Label>Bank Verification Number (BVN)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your BVN"
                            name="bvn"
                            value={formData.bvn}
                            onChange={handleChange}
                            required
                            maxLength={11}
                            minLength={11}
                        />
                    </Form.Group>

                    <Form.Group controlId="nin" className="mb-3">
                        <Form.Label>
                            National Identification Number (NIN)
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your NIN"
                            name="nin"
                            value={formData.nin}
                            onChange={handleChange}
                            maxLength={11}
                            minLength={11}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="phone" className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your phone number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="password" className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Create a password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    {error && <p className="text-danger">{error}</p>}

                    <Button
                        type="submit"
                        className="qp-btn-green w-100"
                        disabled={loading}
                    >
                        {loading ? "Creating account..." : "Create Account"}
                    </Button>
                </Form>
            </div>
        </div>
    );
}
