export default function DeletingAccountScreen() {
    return (
        <div>
            <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                <div></div>
                <div className="">
                    <h1 className="display-4 text-white">Cookie Policy</h1>
                </div>
                <div></div>
            </div>
            <div className="px-5 pt-3">
                <div>
                    Welcome to our Cookie Policy. This policy outlines how we
                    use cookies on our website. By using our website, you agree
                    to the use of cookies as described in this policy.
                </div>
                <div className="my-3">
                    <ol>
                        <li>
                            <strong>What Are Cookies:</strong> Cookies are small
                            pieces of data stored on your device (computer or
                            mobile device) that help improve your browsing
                            experience. They remember your preferences, enhance
                            functionality, and provide analytics.
                        </li>
                        <li>
                            <strong>How We Use Cookies:</strong> We use cookies
                            for various purposes, including but not limited to
                            analyzing site usage, personalizing content, and
                            improving overall user experience.
                        </li>
                        <li>
                            <strong>Types of Cookies:</strong> Our website uses
                            essential cookies that are necessary for the site's
                            functionality and performance cookies for analytics.
                            We do not use third-party cookies for advertising.
                        </li>
                        <li>
                            <strong>Cookie Control:</strong> You can control and
                            manage cookies in your browser settings. However,
                            restricting cookies may impact the functionality of
                            our website.
                        </li>
                        <li>
                            <strong>Consent:</strong> By continuing to use our
                            website, you consent to the use of cookies as
                            outlined in this policy.
                        </li>
                    </ol>
                </div>
                <div>
                    <p>
                        If you have any questions or concerns about our Cookie
                        Policy, please contact our support team.
                    </p>
                </div>
            </div>
        </div>
    );
}
