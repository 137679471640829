import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDocumentAction } from "src/actions/documentsAction";
import { enqueueSnackbar } from "notistack";
import Loader from "src/components/Loader";

export default function PrivacyPolicyScreen() {
    const dispatch = useDispatch();
    const [policyDocument, setPolicyDocument] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        // @ts-ignore
        dispatch(getDocumentAction(2))
            // @ts-ignore
            .then((response) => {
                setPolicyDocument(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.toString(), {
                    variant: "error",
                    preventDuplicate: true,
                });
            });
    }, [dispatch]);

    return isLoading ? (
        <Loader />
    ) : (
        Object.keys(policyDocument).length > 0 && (
            <div>
                <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                    <div className="">
                        <h1 className="display-4 text-white">
                            {policyDocument.title}
                        </h1>
                    </div>
                </div>
                <div className="px-5 py-3">{policyDocument.introduction}</div>
                <div className="px-5 pb-3">
                    {policyDocument.points.map((point) => (
                        <div key={point.id} className="mb-3">
                            <div className="fw-bold qp-text-green">
                                {point.title}
                            </div>
                            <div>{point.content}</div>
                        </div>
                    ))}
                </div>
            </div>
        )
    );
}
