import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { confirmEmailAction } from "src/actions/userActions";
import Loader from "src/components/Loader";
import EmailConfirmationSuccessfulScreen from "./EmailConfirmationSuccessfulScreen";
import NotFoundScreen from "./NotFoundScreen";

export default function ConfirmEmailScreen() {
    const dispatch = useDispatch();
    let { uidb, token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        // @ts-ignore
        dispatch(confirmEmailAction(uidb, token))
            // @ts-ignore
            .then((response) => {
                setIsLoading(false);
                enqueueSnackbar(response, {
                    variant: "success",
                    preventDuplicate: true,
                });
                setEmailConfirmed(true);
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.toString(), {
                    variant: "error",
                    preventDuplicate: true,
                });
            });
    }, [dispatch, uidb, token]);
    return isLoading ? (
        <Loader />
    ) : !emailConfirmed ? (
        <NotFoundScreen />
    ) : (
        <EmailConfirmationSuccessfulScreen />
    );
}
