import axios from "axios";

export const confirmEmailAction = (uidb, token) => async () => {
    try {
        const { data } = await axios.get(`activate/${uidb}/${token}/`);
        return data;
    } catch (error) {
        const errorMessage =
            error.response && error.response.data
                ? error.response.data
                : "We encountered an error, please check back later";

        throw errorMessage;
    }
};
