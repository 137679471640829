import { Col, Row, Container } from "react-bootstrap";
import HomePagePhones from "../assets/images/home_page_phones.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faGift, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
// import SecureImage from "../assets/images/secure.png"; // Placeholder for security section image
// import RewardsImage from "../assets/images/rewards.png"; // Placeholder for rewards section image

export default function HomeScreen() {
    return (
        <div>
            {/* Hero Section */}
            <section className="bg-light text-center py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <h1
                                className="qp-text-green"
                                style={{ fontSize: "2.5em" }}
                            >
                                <strong>
                                    Simplifying your Payments,
                                    <br /> Empowering your Life!
                                </strong>
                            </h1>
                            <p>
                                Receive Money, Make Seamless Bill Payments, and
                                Effortlessly Transfer Funds with QuickPurse.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img
                                src={HomePagePhones}
                                alt="QuickPurse Mobile App"
                                width="100%"
                                className="img-fluid"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Features Section */}
            <section className="py-5">
                <Container>
                    <h2 className="text-center mb-4 qp-text-green">
                        Why Choose QuickPurse?
                    </h2>
                    <Row className="text-center">
                        <Col md={4} className="mb-4">
                            <div className="mb-3">
                                <FontAwesomeIcon
                                    icon={faShieldAlt}
                                    size="3x"
                                    className="text-primary"
                                />
                            </div>
                            <h5>Secure Transactions</h5>
                            <p>
                                Your data and transactions are protected with
                                top-notch security.
                            </p>
                        </Col>
                        <Col md={4} className="mb-4">
                            <div className="mb-3">
                                <FontAwesomeIcon
                                    icon={faGift}
                                    size="3x"
                                    className="text-success"
                                />
                            </div>
                            <h5>Earn Rewards</h5>
                            <p>
                                Get exclusive cashback and discounts every time
                                you make a bill payment.
                            </p>
                        </Col>
                        <Col md={4} className="mb-4">
                            <div className="mb-3">
                                <FontAwesomeIcon
                                    icon={faBolt}
                                    size="3x"
                                    className="text-warning"
                                />
                            </div>
                            <h5>Quick Payments</h5>
                            <p>
                                Make payments and transfers in seconds,
                                hassle-free.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Testimonials Section */}
            <section className="bg-light py-5 mb-5">
                <Container>
                    <h2 className="text-center mb-4 qp-text-green">
                        What Our Users Say
                    </h2>
                    <Row>
                        <Col md={4}>
                            <blockquote className="blockquote text-center">
                                <p>
                                    “QuickPurse has changed how I manage my
                                    finances. It’s very fast and easy to use!”
                                </p>
                                <footer className="blockquote-footer">
                                    Vivian O.
                                </footer>
                            </blockquote>
                        </Col>
                        <Col md={4}>
                            <blockquote className="blockquote text-center">
                                <p>
                                    “I’ve saved so much with their bill purchase
                                    commission. Highly recommend!”
                                </p>
                                <footer className="blockquote-footer">
                                    Obinna U.
                                </footer>
                            </blockquote>
                        </Col>
                        <Col md={4}>
                            <blockquote className="blockquote text-center">
                                <p>
                                    “The security and reliability are top-notch.
                                    I trust QuickPurse for all my payments.”
                                </p>
                                <footer className="blockquote-footer">
                                    Adewale A.
                                </footer>
                            </blockquote>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}
