import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./static/css/index.css";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import store from "./store.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <React.StrictMode>
            <SnackbarProvider
                autoHideDuration={5000}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
                <App />
            </SnackbarProvider>
        </React.StrictMode>
    </Provider>
);
