import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useState } from "react";
import Loader from "src/components/Loader";

export default function ContactUsScreen() {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const submitContactUsForm = async () => {
        setIsLoading(true);
        try {
            await axios.post("global/contact_us/");
            enqueueSnackbar(
                "Thank you for contacting us, we will get back to you within 3 working days.",
                { variant: "success" }
            );
        } catch (error) {
            enqueueSnackbar("An error occured, please try again later.", {
                variant: "error",
            });
        } finally {
            setIsLoading(false);
            setName("");
            setEmail("");
            setMessage("");
        }
    };

    return isLoading ? (
        <Loader />
    ) : (
        <div>
            {/* Header Section */}
            <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                <div className="text-center">
                    <h1 className="display-4 text-white">Contact Us</h1>
                    <p className="text-white">
                        Have questions or need assistance? We're here to help!
                    </p>
                </div>
            </div>

            {/* Contact Details Section */}
            <Container className="pt-5">
                <Row className="text-center mb-5">
                    <Col md={4} className="">
                        <i className="fas fa-envelope fa-3x qp-text-green mb-3"></i>
                        <h5 className="qp-text-green">Email Us</h5>
                        <p>
                            <a
                                href="mailto:quickpurse@temilimited.com"
                                className="text-decoration-none text-black"
                            >
                                quickpurse@temilimited.com
                            </a>
                        </p>
                    </Col>
                    <Col md={4} className="">
                        <i className="fas fa-phone fa-3x mb-3 qp-text-green"></i>
                        <h5 className="qp-text-green">Call Us</h5>
                        <p>+2348141990794</p>
                    </Col>
                    <Col md={4} className="">
                        <i className="fas fa-map-marker-alt fa-3x qp-text-green mb-3"></i>
                        <h5 className="qp-text-green">Visit Us</h5>
                        <p>
                            Office 7, Block B1, Rauf Aregbesola Complex, Ipaja,
                            Lagos.
                        </p>
                    </Col>
                </Row>
            </Container>

            {/* Contact Form Section */}
            <div className="py-5" style={{ backgroundColor: "#f9f9f9" }}>
                <Container>
                    <h2
                        className="text-center mb-4"
                        style={{ color: "#28a745" }}
                    >
                        Send Us a Message
                    </h2>
                    <Row className="justify-content-center">
                        <Col md={8}>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    submitContactUsForm();
                                }}
                            >
                                <Form.Group controlId="name" className="mb-3">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="email" className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        required
                                    />
                                </Form.Group>
                                <Form.Group
                                    controlId="message"
                                    className="mb-3"
                                >
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Enter your message"
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    type="submit"
                                    className="qp-btn-green w-100"
                                >
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
