export default function DeletingAccountScreen() {
    return (
        <div>
            <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                <div className="">
                    <h1 className="display-4 text-white">
                        Deleting My Account
                    </h1>
                </div>
            </div>
            <div className="px-5 pt-3">
                <div>
                    Welcome to our account deletion process. We understand that
                    circumstances may change, and users should have the freedom
                    to delete their accounts if needed. Before proceeding,
                    please be aware that this process is irreversible, and all
                    associated data will be permanently deleted. Take a moment
                    to ensure that you want to proceed with deleting your
                    account. The following steps show how to do this:
                </div>
                <div className="my-3">
                    <ol>
                        <li>
                            <strong>Login to the App:</strong> Log in to your
                            account using your credentials.
                        </li>
                        <li>
                            <strong>Navigate to Profile:</strong> Click on the
                            "Profile" tab or button in the navigation menu to
                            access your profile.
                        </li>
                        <li>
                            <strong>Access Account Deletion:</strong> Within
                            your profile, you'll find a "Delete Account" option.
                            Click on it to initiate the deletion process.
                        </li>
                        <li>
                            <strong>Confirmation Pop-up:</strong> A confirmation
                            pop-up will appear to ensure you're certain about
                            deleting your account.
                        </li>
                        <li>
                            <strong>Display Confirmation Message:</strong> The
                            pop-up will contain a message confirming the
                            irreversible nature of this action.
                        </li>
                        <li>
                            <strong>Yes, Delete Button:</strong> Click the "Yes,
                            Delete" button to confirm your decision and proceed
                            with the deletion.
                        </li>
                        <li>
                            <strong>No Button:</strong> If you change your mind,
                            you can click on "No" to abort the deletion process.
                        </li>
                        <li>
                            <strong>Provide Warnings:</strong> Additional
                            warnings will remind you about potential data loss
                            and the permanency of this action.
                        </li>
                        <li>
                            <strong>Authentication Check:</strong> For added
                            security, you promted to re-enter your password
                            before confirming the deletion.
                        </li>
                        <li>
                            <strong>Process Deletion:</strong> Once confirmed,
                            your account will be permanently deleted, and you'll
                            be logged out of the app.
                        </li>
                        <li>
                            <strong>Display Confirmation Message:</strong> A
                            final confirmation message will let you know that
                            your account has been successfully deleted.
                        </li>
                    </ol>
                </div>
                <div>
                    <p>
                        Thank you for being part of our community. If you have
                        any questions or concerns, feel free to reach out to our
                        support team.
                    </p>
                </div>
            </div>
        </div>
    );
}
