import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import Loader from "src/components/Loader";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

export default function LoginScreen() {
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        try {
            await axios.post("login/", {
                email,
                password,
            });
        } catch (err) {
            enqueueSnackbar(
                "Your username and password combination is incorrect.",
                { variant: "error" }
            );
        } finally {
            setLoading(false);
        }
    };

    return loading ? (
        <Loader />
    ) : (
        <div className="d-flex justify-content-center my-5">
            <div className="w-100 mx-3" style={{ maxWidth: "400px" }}>
                <h1
                    className="qp-text-green text-center"
                    style={{ fontSize: "2.5em" }}
                >
                    <strong>Welcome Back!</strong>
                </h1>
                <h6 className="text-center mb-4">
                    Please log in to access your account.
                </h6>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="password" className="">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <div className="text-end fw-bold qp-text-green mb-3 mt-1">
                        <Link
                            className="text-decoration-none qp-text-green"
                            to={"/forgot_password"}
                        >
                            Forgot Password?
                        </Link>
                    </div>

                    {error && <p className="text-danger">{error}</p>}

                    <Button
                        type="submit"
                        className="qp-btn-green w-100 border-0"
                        disabled={loading}
                    >
                        {loading ? "Logging in..." : "Log In"}
                    </Button>
                    <div className="fw-bold text-center mt-2">
                        Don't have an account?{" "}
                        <Link
                            className="text-decoration-none qp-text-green"
                            to={"/create_account"}
                        >
                            Register
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
}
