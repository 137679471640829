import { Link } from "react-router-dom";

export default function FAQScreen() {
    return (
        <div>
            <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                <div className="">
                    <h1 className="display-4 text-white">FAQs</h1>
                </div>
            </div>
            <div className="px-5 pt-3">
                <ul className="qp-text-green fs-5 fw-bold">
                    <li>
                        <Link to={"/faqs/privacy_policy"}>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to={"/faqs/cookie_policy"}>Cookie Policy</Link>
                    </li>
                    <li>
                        <Link to={"/faqs/deleting_account"}>
                            Deleting My Account
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
