import CookieConsent from "react-cookie-consent";
import { Link, Outlet } from "react-router-dom";
import Footer from "src/components/Footer";
import Header from "src/components/Header";

export default function App() {
    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Dismiss"
                cookieName="QuickPurseCookie"
                style={{ background: "#4caf50" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={180}
            >
                By using this website, you agree to our use of cookies in
                accordance with our{" "}
                <Link to={"/faqs/cookie_policy"} className="text-white">
                    Cookie Policy
                </Link>
                .
            </CookieConsent>
        </>
    );
}
