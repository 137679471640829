import axios from "axios";

export const getDocumentAction = (id) => async () => {
    try {
        const { data } = await axios.get(`global/legal_documents/${id}/`);
        return data;
    } catch (error) {
        const errorMessage = error.response.message
            ? error.response.message
            : "We encountered an error, please check back later";

        throw errorMessage;
    }
};
