import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import Loader from "src/components/Loader";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

export default function ForgotPasswordScreen() {
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.put("send_password_reset_email/", { email });
            setSuccess(true);
            enqueueSnackbar(
                "Password reset link has been sent to your email.",
                {
                    variant: "success",
                }
            );
        } catch (err) {
            enqueueSnackbar(
                "We couldn't find an account associated with that email address.",
                { variant: "error" }
            );
        } finally {
            setLoading(false);
        }
    };

    return loading ? (
        <Loader />
    ) : success ? (
        <div className="d-flex justify-content-center my-5">
            <div
                className="w-100 mx-3 text-center"
                style={{ maxWidth: "400px" }}
            >
                <i
                    className="fa fa-check-circle qp-text-green"
                    style={{ fontSize: "60px" }}
                ></i>
                <h1
                    className="qp-text-green mt-3"
                    style={{ fontSize: "2.5em" }}
                >
                    <strong>Check Your Email</strong>
                </h1>
                <p className="mt-3">
                    If an account exists with the provided email address, you
                    will receive a link to reset your password shortly.
                </p>
                <Link
                    className="btn qp-btn-green mt-4 w-100 border-0"
                    to={"/login"}
                >
                    Back to Login
                </Link>
            </div>
        </div>
    ) : (
        <div className="d-flex justify-content-center my-5">
            <div className="w-100 mx-3" style={{ maxWidth: "400px" }}>
                <h1
                    className="qp-text-green text-center"
                    style={{ fontSize: "2.5em" }}
                >
                    <strong>Reset Password</strong>
                </h1>
                <h6 className="text-center mb-4">
                    Enter your email address to receive a reset link.
                </h6>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button
                        type="submit"
                        className="qp-btn-green w-100 border-0"
                        disabled={loading}
                    >
                        {loading ? "Sending reset link..." : "Send Reset Link"}
                    </Button>

                    <div className="fw-bold text-center mt-2">
                        Remember your password?{" "}
                        <Link
                            className="text-decoration-none qp-text-green"
                            to={"/login"}
                        >
                            Log In
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
}
